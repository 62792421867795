<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="notice_detail"
      space="40"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="2"
          >
            <div class="d-flex flex-column">
              <v-card
                :class="['rounded-lg', 'transition-swing']"
                color="white"
                flat
                tile
              >
                <v-img
                  :src="currPerson.image"
                  aspect-ratio="0.69"
                  class="align-end"
                  height="inherit"
                />
              </v-card>
              <div class="d-flex flex-row justify-center items-center co-w-full mt-4">
                <span class="text-h6">{{ currPerson.name }}</span>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <div class="d-flex flex-column co-border co-border-gray-400 co-round co-p8">
              <span class="text-h6 co-mb6">分工</span>
              <span>
                <span class="co-py2 co-px8" />
                {{ currPerson.work }}
              </span>
              <span class="text-h6 co-my6 co-pt6">简历</span>
              <span>
                <span class="co-py2 co-px8" />
                {{ currPerson.resume }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <div class="d-flex flex-column co-bg-gray-100 co-border co-border-gray-400 co-round co-p8">
              <div
                v-for="(per, perIndex) in persons"
                :key="`per_${perIndex}`"
                class="d-flex flex-column"
              >
                <div class="d-flex flex-row">
                  <a
                    :class="['co-w56', 'co-mr4', 'co-shrink0',
                             per.name === currPerson.name ? 'co-font-600' : ''
                    ]"
                    @click="onPerson(per, perIndex)"
                  >
                    {{ per.name }}
                  </a>
                  <span>{{ per.duty }}</span>
                </div>
                <div
                  v-if="perIndex < (persons.length - 1)"
                  class="co-border-b co-border-gray-400 co-my8"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
  </section>
</template>

<script>
  import mixDiscloseBanner from '@/pages/mixins/disclose/mix.disclose.banner'

  import dataPersons from '@/data/data.park.persons.json'

  export default {
    metaInfo: { title: '领导分工' },
    mixins: [mixDiscloseBanner],
    data () {
      return {
        currPerson: dataPersons.items[0],
        persons: dataPersons.items
      }
    },
    created () {
      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()
    },
    methods: {
      onPerson (item, index) {
        this.currPerson = this.persons[index]
      }
    }
  }
</script>
